import React from 'react';

const ClientsSection = () => {
  return (
    <section className="clients style-5 pb-20 " style={{ backgroundColor: '#f3fbff' }}>
      <div className="container py-5">
        <div className="row row-cols-1 row-cols-md-2 g-4 px-md-0 px-3">
          <div className="section-head text-start mb-40 style-5 pt-5 col my-auto">
            <div className="row row-cols-1 row-cols-md-2 g-4 px-md-0 px-3">
              <div className="col">
                <div className="cta-card new text-center h-100">
                  <ul className="cta-usp new">
                    <li className="list-item cta d-flex p-1">
                      <div className="html-embed-27 cta w-embed">
                        <svg
                          width="16"
                          height="12"
                          viewBox="0 0 18 14"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.60003 10.6L1.40003 6.4L3.05176e-05 7.8L5.60003 13.4L17.6 1.4L16.2 0L5.60003 10.6Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="feature-text-2 text-start ps-2">
                        Experience a personalized demo with us
                      </div>
                    </li>
                    <li className="list-item cta d-flex p-1">
                      <div className="html-embed-27 cta w-embed">
                        <svg
                          width="16"
                          height="12"
                          viewBox="0 0 18 14"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.60003 10.6L1.40003 6.4L3.05176e-05 7.8L5.60003 13.4L17.6 1.4L16.2 0L5.60003 10.6Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="feature-text-2 text-start ps-2">
                        Discover why 10,000+ businesses choose us
                      </div>
                    </li>
                  </ul>
                  <a
                    href="https://calendly.com/info-w0m/30min?month=2024-02"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn bg-green text-white btn-color text-center mt-10"
                  >
                    Book A Demo
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="cta-card new text-center h-100">
                  <ul  className="cta-usp new">
                    <li className="list-item cta d-flex p-1">
                      <div className="html-embed-27 cta w-embed">
                        <svg
                          width="16"
                          height="12"
                          viewBox="0 0 18 14"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.60003 10.6L1.40003 6.4L3.05176e-05 7.8L5.60003 13.4L17.6 1.4L16.2 0L5.60003 10.6Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="feature-text-2 text-start ps-2">
                        Join a rewarding, value-based partner program.
                      </div>
                    </li>
                    <li className="list-item cta d-flex p-1">
                      <div className="html-embed-27 cta w-embed">
                        <svg
                          width="16"
                          height="12"
                          viewBox="0 0 18 14"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.60003 10.6L1.40003 6.4L3.05176e-05 7.8L5.60003 13.4L17.6 1.4L16.2 0L5.60003 10.6Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className="feature-text-2 text-start ps-2">
                        Expand services & Unlock New Revenue Channels
                      </div>
                    </li>
                  </ul>
                  <a
                    href="../partner-with-us"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn login_button mt-10"
                  >
                    Become a Partner
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="section-head ps-5 text-start mb-40 style-5 pt-5 col">
            <h2 className="mb-4">
              Get Started With <br/><span>Anantya.ai</span>
            </h2>
            <p>We Empower Thousands Of Businesses With Faster, Better, And Stronger Communication.</p>
          </div>
        </div>
      </div>
      <hr className="line_breaker_2" />
    </section>
  );
};

export default ClientsSection;
