import React from "react";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "./BlogSlider.css"; // Add necessary styles for Swiper
import { getImage } from "../../utils/getImage"; // Adjust the path as needed
// Blog images using the getImage function
const img1 = getImage(
  "blog/whatsapp-business-app-vs-whatsapp-business-api.webp"
);
const img2 = getImage("blog/5-winning-whatsapp-marketing-strategies.webp");
const img3 = getImage("blog/from-vision-to-reality-how-anantya.ai.webp");
const img4 = getImage(
  "blog/boost-sales-on-festive-season-with-ai-chatbots.jpeg"
);
const img5 = getImage("blog/gitexblog.jpeg");
const img6 = getImage("blog/click-to-whatsapp-ads.jpeg");
const img7 = getImage("blog/whatsapp-business-api-your-ultimate-guide.jpg");
const img8 = getImage(
  "blog/whatsapp-broadcasting-your-ultimate-guide-whatsapp-broadcast-your-complete-business-booster-guide.webp"
);
const img9 = getImage("blog/whatsapp-for-healthcare.webp");
const img10 = getImage("blog/whatsapp-chatbot-for-business.webp");
const img11 = getImage("blog/whatsapp-business-api-in-banking-sector.webp");
const img12 = getImage("blog/meta-frequency-capping-update.webp");
const img13 = getImage(
  "blog/whatsapp-marketing-direct-communication-with-customers.webp"
);
const img14 = getImage("blog/whatsapp-business-api-conversation-pricing.webp");
const img15 = getImage(
  "blog/whatsapp-business-switching-from-green-tick-to-blue-tick.webp"
);
const img16 = getImage("blog/whatsapp-authentication-rates.webp");
const img17 = getImage("blog/whatsapp-free-entry-frequency-capping.webp");
const img18 = getImage("blog/whatsapp-broadcast-list-and-its-features.webp");
const img19 = getImage("blog/whatsapp-business-api-for-government.webp");
const img20 = getImage("blog/whatsapp-business-api-providers-2024.webp");
const img21 = getImage(
  "blog/meta-whatsapp-business-update-for-alcohol-gaming-and-gambling-promotion.webp"
);

const BlogSlider = () => {
  React.useEffect(() => {
    // Initialize Swiper
    const swiper = new Swiper(".swiper-container", {
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    // Cleanup Swiper instance on unmount
    return () => {
      if (swiper) {
        swiper.destroy();
      }
    };
  }, []);

  return (
    <section className="blog_Slider related-posts bg-gray5">
      <div className="container py-5">
        <h5 className=" pb-5 display-6">Recommended Resources</h5>
        <div className="related-postes-slider position-relative">
          <div className="swiper-container">
            <div className="swiper-wrapper blog">
              {/* Slide 1 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-business-app-vs-whatsapp-business-api"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img1}
                      className="card-img-top"
                      alt="WhatsApp Business App vs WhatsApp Business API"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-business-app-vs-whatsapp-business-api">
                        WhatsApp Business App vs WhatsApp Business API
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      When WhatsApp introduced the WhatsApp Business app, it
                      came out as a great tool for small businesses to interact
                      with customers.
                    </p>
                    <a href="/blog/whatsapp-business-app-vs-whatsapp-business-api">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 2 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-marketing-strategies-to-drive-sales"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img2}
                      className="card-img-top"
                      alt="5 Winning WhatsApp Marketing Strategies"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-marketing-strategies-to-drive-sales">
                        5 Winning WhatsApp Marketing Strategies to Drive 3X More
                        Sales
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      As of now, the world's population is around 7.5 billion
                      people. And guess what?
                    </p>
                    <a href="/blog/whatsapp-marketing-strategies-to-drive-sales">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 3 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/reshaping-customer-engagement-on-whatsapp"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img3}
                      className="card-img-top"
                      alt="Reshaping Customer Engagement on WhatsApp"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/reshaping-customer-engagement-on-whatsapp">
                        Reshaping Customer Engagement on WhatsApp
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Anantya.ai is Reshaping Customer Engagement on WhatsApp.
                      The journey started when we realized that the existing
                      customer engagement.
                    </p>
                    <a href="/blog/reshaping-customer-engagement-on-whatsapp">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 4 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/boost-sales-on-festive-season-with-ai-chatbots"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img4}
                      className="card-img-top"
                      alt="boost-sales-on-festive-season-with-ai-chatbots"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/boost-sales-on-festive-season-with-ai-chatbots">
                        WhatsApp Business API Conversation Pricing is Changing!
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      With billions of users worldwide, WhatsApp has established
                      a well-known presence among users..
                    </p>
                    <a href="/blog/boost-sales-on-festive-season-with-ai-chatbots">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 5 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/gitex-2023-highlights-anantya-ai-ai-powered-messaging-evolution"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img src={img5} className="card-img-top" alt="gitex-2023" />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/gitex-2023-highlights-anantya-ai-ai-powered-messaging-evolution">
                        GITEX 2023 Highlights: Anantya.ai Leads the AI-Powered
                        Messaging Evolution
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Anantya.ai, a leading WhatsApp Business API solution
                      provider, made a significant presence at GITEX 2023, the
                      world's premier global technology exhibition..
                    </p>
                    <a href="/blog/gitex-2023-highlights-anantya-ai-ai-powered-messaging-evolution">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 6 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/click-to-whatsapp-ads-marketing-2023"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img6}
                      className="card-img-top"
                      alt="click-to whatsapp-ads"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/click-to-whatsapp-ads-marketing-2023">
                        Elevate Your Marketing in 2023 with Click-to-WhatsApp
                        Ads: A Game-Changing Strategy
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      WhatsApp Business API has already revolutionized the way
                      that consumers and businesses interact and maintain
                      connections.
                    </p>
                    <a href="/blog/click-to-whatsapp-ads-marketing-2023">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 7 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-business-api-ultimate-guide"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img7}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-business-api-ultimate-guide">
                        WhatsApp Business API: Your Ultimate 2024 Guide
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Have you ever wondered how businesses manage to stay
                      connected with their customers through WhatsApp smoothly
                      and efficiently?
                    </p>
                    <a href="/blog/whatsapp-business-api-ultimate-guide">
                      {" "}
                      Read More
                    </a>{" "}
                  </div>
                </div>
              </div>
              {/* Slide 8 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-broadcasting-ultimate-guide"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img8}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-broadcasting-ultimate-guide">
                        WhatsApp Broadcast: Your Complete Business Booster Guide
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Eager to learn about WhatsApp broadcasting! Will using
                      WhatsApp broadcasting bring ROI to your business?
                    </p>
                    <a href="/blog/whatsapp-broadcasting-ultimate-guide">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 9 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-for-healthcare-a-simple-guide"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img9}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-for-healthcare-a-simple-guide">
                        How to Use WhatsApp for Healthcare: A Simple Guide
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      With a large user base of 2.79 billion people globally,
                      WhatsApp is not only just for chatting; it’s also helping
                      to save lives!
                    </p>
                    <a href="/blog/whatsapp-for-healthcare-a-simple-guide">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 10 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/how-to-create-a-whatsapp-chatbot-for-business"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img10}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/how-to-create-a-whatsapp-chatbot-for-business">
                        How to Create a WhatsApp ChatBot in Just a Few Steps
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Looking for different ways to boost your business or
                      provide better customer support while using a WhatsApp
                      chatbot?
                    </p>
                    <a href="/blog/how-to-create-a-whatsapp-chatbot-for-business">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 11 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-business-api-for-banking-sector"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img11}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-business-api-for-banking-sector">
                        Use of the WhatsApp Business API in the Banking Sector
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      We all receive messages and notifications from banks, be
                      it offers of new savings accounts or credit card schemes..
                    </p>
                    <a href="/blog/whatsapp-business-api-for-banking-sector">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 12 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/meta-new-frequency-capping-update"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img12}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/meta-new-frequency-capping-update">
                        What will change after Meta’s new frequency capping
                        update?
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Nowadays, WhatsApp is being used for marketing and has
                      gained lots of popularity. This sounds pleasant while
                    </p>
                    <a href="/blog/meta-new-frequency-capping-update">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 13 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-marketing-a-direct-communication-with-customers"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img13}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-marketing-a-direct-communication-with-customers">
                        Whatsapp Marketing: Build a Direct Line of Communication
                        with Customers
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Have you ever thought of using WhatsApp for more than just
                      chatting with friends or sending funny reels and videos?
                    </p>
                    <a href="/blog/whatsapp-marketing-a-direct-communication-with-customers">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 14 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-business-api-pricing"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img14}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-business-api-pricing">
                        WhatsApp Business API Conversation Pricing is Changing!
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      With billions of users worldwide, WhatsApp has established
                      a well-known presence among users..
                    </p>
                    <a href="/blog/whatsapp-business-api-pricing"> Read More</a>
                  </div>
                </div>
              </div>
              {/* Slide 15 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-business-switching-from-green-tick-to-blue-tick"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img15}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-business-switching-from-green-tick-to-blue-tick">
                        Why WhatsApp Business is Switching from Green Tick to
                        Blue Tick Verification
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Have you recently noticed any changes in WhatsApp? No… no…
                      I am not talking about the app’s upgraded version..
                    </p>
                    <a href="/blog/whatsapp-business-switching-from-green-tick-to-blue-tick">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 16 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/change-in-whatsapp-international-authentication-pricing"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img16}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/change-in-whatsapp-international-authentication-pricing">
                        Telecom Revolt on International Authentication Messages:
                        The WhatsApp Pricing Change
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Reaching millions of people with just a single click, the
                      world has grown digitally. WhatsApp has become the
                      first-ever...
                    </p>
                    <a href="/blog/change-in-whatsapp-international-authentication-pricing">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 17 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-free-entry-frequency-capping"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img17}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-free-entry-frequency-capping">
                        WhatsApp’s Free Entry Point and Frequency Capping in
                        2024: What Businesses Need to Know
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      With the 2024 update, Meta’s new change in WhatsApp has
                      shaken things up. This update has limited how..
                    </p>
                    <a href="/blog/whatsapp-free-entry-frequency-capping">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 18 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-broadcast-list"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img18}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-broadcast-list">
                        How to Create a WhatsApp Broadcast List and Its Features
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Have you ever wondered about sending out messages to
                      hundreds of customers on WhatsApp without ..
                    </p>
                    <a href="/blog/whatsapp-broadcast-list"> Read More</a>
                  </div>
                </div>
              </div>
              {/* Slide 19 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-business-api-for-government"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img19}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-business-api-for-government">
                        How the Government Can Use WhatsApp Business API to
                        Serve Indian Citizens
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Have you ever visited a government office? After seeing
                      such a huge waiting queue, do you also feel frustrated or
                      maybe just want to go back home?
                    </p>
                    <a href="/blog//whatsapp-business-api-for-government">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 20 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/whatsapp-business-api-providers"
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img20}
                      className="card-img-top"
                      alt="Additional Content 3"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/whatsapp-business-api-providers">
                        Top WhatsApp Business API Providers in 2024
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      “Where there are people, there is business”. With nearly 3
                      billion users, WhatsApp is quickly becoming the first
                      choice for communication platforms to market their
                      products.
                    </p>
                    <a href="/blog/whatsapp-business-api-providers">
                      {" "}
                      Read More
                    </a>{" "}
                  </div>
                </div>
              </div>
              {/* Slide 21 */}
              <div className="swiper-slide">
                <div className="card border-0 bg-transparent rounded-0 p-0 d-block">
                  <a
                    href="/blog/meta-whatsapp-update-for-alcohol-gaming-and-gambling-promotion "
                    className="img radius-7 overflow-hidden img-cover"
                  >
                    <img
                      src={img21}
                      className="card-img-top"
                      alt="meta-whatsapp-update"
                    />
                  </a>
                  <div className="card-body px-0">
                    <h5 className="fw-bold mt-10 title">
                      <a href="/blog/meta-whatsapp-update-for-alcohol-gaming-and-gambling-promotion">
                        Meta’s WhatsApp Business Update: Transforming Key
                        Industries
                      </a>
                    </h5>
                    <p className="small mt-2 op-8">
                      Big news! Meta has announced a new update that opens up
                      new possibilities for key industries on the WhatsApp
                      Business Platform. Starting from{" "}
                    </p>
                    <a href="/blog/meta-whatsapp-update-for-alcohol-gaming-and-gambling-promotion">
                      {" "}
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>
    </section>
  );
};

export default BlogSlider;
