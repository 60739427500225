import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./YoutubeVideoSlider.css";

const YoutubeVideoSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: true, // Prevent autoplay flickering when hovered
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const videos = [
    { id: "0BIScxjyEMk" },
    { id: "daa1bYEfv9c" },
    { id: "-EnTeJddC80" },
    { id: "dlBvpU-t1r8" },
    { id: "sPd-b2SobbU" },
    { id: "ozBMhE9o7nU" },
  ];

  return (
    <div className="video-slider-container mb-5">
      <h2>
        Watch <span>Anantya.ai</span> in Action 🚀
      </h2>
      <p>Discover how Anantya.ai is transforming customer engagement with smart, easy-to-use solutions.</p>
      <Slider {...settings}>
      {videos.map((video, index) => (
  <div key={`video-${index}`} className="video-card my-3">
    <div className="video-wrapper">
      <iframe
        width="100%"
        height="200px"
        src={`https://www.youtube.com/embed/${video.id}`}
        title={`YouTube video ${index + 1}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <a
        className="overlay"
        href={`https://www.youtube.com/watch?v=${video.id}&autoplay=1`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="sr-only">Open video in YouTube</span>
      </a>
    </div>
  </div>
      ))}

      </Slider>
    </div>
  );
};

export default YoutubeVideoSlider;
