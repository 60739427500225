import React from "react";

import "./SidebarInfo.css"; // Importing the CSS

const SidebarInfo = () => {
  return (
    <div className="sidebar-info specific-section">
  <ul>
  <li>Publisher</li>
  <li>Meta</li>
  <li>Category</li>
  <li>Messaging Channels</li>
  <li>Available on</li>
  <li>Team</li>
  <li>Business</li>
  <li>Enterprise</li>
</ul>

      <div className="mt-2 text-center">
        <a href="/contact" target="_blank" className="btn login_button">
          Sign In
        </a>
      </div>
    </div>
  );
};

export default SidebarInfo;
