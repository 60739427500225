import React from 'react';
import './OnboardingProcess.css'; // Importing the CSS file
// Importing images
import { getImage } from '../../utils/getImage';  // Correct path to utility
   // Fetching images dynamically using getImage function
   const sealTheDeal = getImage('sales-hub/seal-the-deal-and-kickstart-success.webp');
   const hassleFreeOnboarding = getImage('sales-hub/Hassle-free-client-onboarding.webp');
   const addSubheading = getImage('sales-hub/Add a subheading.webp');
   const onboardingMeeting = getImage('sales-hub/onboarding-meeting.webp');
   const unlockSuccess = getImage('sales-hub/unlock-success-with-account.webp');
   const readySetLaunch = getImage('sales-hub/tailored-account-training-and-setup.webp');
   const ongoingSupport = getImage('sales-hub/Smooth-Sailing-ahead.webp');

const OnboardingProcess = () => {
    const sections = [
        {
            id: 'section6',
            number: '01',
            backgroundColor: '#76C6B7',
            heading: 'Seal the Deal and Kickstart Success!',
            text: "Congratulations on securing a commitment from your client! Now, let's swiftly move forward to transform that promise into reality.",
            imgSrc: sealTheDeal,
            imgAlt: 'seal-the-deal-and-kickstart-success',
        },
        {
            id: 'section7',
            number: '02',
            backgroundColor: '#F9E4BD',
            heading: 'Hassle-Free Client Onboarding',
            text: "After receiving payment or signing the agreement, send the completed forms to our Region Head, Product Head, Onboarding Team, & Accounts via email. They'll handle the rest, guiding you through each step smoothly.",
            imgSrc: hassleFreeOnboarding,
            imgAlt: 'hassle-free-client-onboarding',
        },
        {
            id: 'section8',
            number: '03',
            backgroundColor: '#49D9FF',
            heading: 'Comprehensive Overview: Onboarding Process',
            text: "The onboarding team will kickstart the process after receiving the email and sales form, introducing the client to the process.",
            imgSrc: addSubheading,
            imgAlt: 'add-a-subheading',
        },
        {
            id: 'section9',
            number: '04',
            backgroundColor: '#74D8B3',
            heading: 'Onboarding Meeting',
            text: "Welcome to the next chapter of our journey together! Our Onboarding team will schedule a meeting to understand the client's needs and provide transparent communication. Let's turn potential into performance. Once the onboarding process is finished, a confirmation email will be promptly sent to the client, letting them know that everything is good to go. This will be followed by the sharing of credentials.",
            imgSrc: onboardingMeeting,
            imgAlt: 'onboarding-meeting',
        },
        {
            id: 'section10',
            number: '05',
            backgroundColor: '#FFBFC5',
            heading: 'Unlock Success with Account Credentials',
            text: "Get ready to empower your clients for success! Our Onboarding Team's Credential Mail is more than just login details – it's the key to unlocking the full potential of our product. Inside, your clients will find:",
            imgSrc: unlockSuccess,
            imgAlt: 'unlock-success-with-account',
        },
        {
            id: 'section11',
            number: '06',
            backgroundColor: '#E0A9A8',
            heading: 'Tailored Account Training and Setup',
            text: "Elevate your experience with our personalized training sessions and account setup services. Our team is committed to making sure your journey is smooth and tailored to meet your specific needs. Let us take the hassle out of getting started and help you reach your goals with ease.",
            imgSrc: readySetLaunch,
            imgAlt: 'ready-set-launch',
        },
        {
            id: 'section12',
            number: '07',
            backgroundColor: '#7BCFFF',
            heading: 'Smooth Sailing Ahead: Handing Over to Support',
            text: "Congratulations! You've reached the final stretch of the sales journey. Now, it's time to hand over the reins to our stellar support team. So sit back, relax, and let our support team steer you towards greatness. Welcome to the support side of success!",
            imgSrc: ongoingSupport,
            imgAlt: 'ongoing-support',
        },
    ];

    return (
        <section>
            {sections.map((section) => (
                <div
                    className="p-5 onboarding-section"
                    id={section.id}
                    style={{ backgroundColor: section.backgroundColor }} // Specific to each section
                    key={section.id}
                >
                    <div className="row">
                        <div className="col-md-6 col-sm-12 my-auto">
                            <h1>{section.number}</h1> {/* Section Number */}
                            <h3>{section.heading}</h3>
                            <p className="mt-30">{section.text}</p>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <img src={section.imgSrc} className="img-fluid" alt={section.imgAlt} />
                        </div>
                    </div>
                </div>
            ))}
        </section>
    );
};

export default OnboardingProcess;
